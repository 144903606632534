* {
    font-family: 'Quicksand', sans-serif;
    color: white;
}

.skills {
    margin: auto;
    width: 70%;
}

.skills--title {
    margin-bottom: 55px;
    font-weight: 900;
    font-size: 46px;

}

.skills--box {
    padding: 15px;
    /* max-width: 120rem; */
    justify-content: center;
    border: 3px solid white;
    background: linear-gradient(135deg, #403F38 0%, #161a1d 50%, #5C225C 100%);
    padding: 10px;
    border-radius: 20px;
    box-shadow: 8px 18px 15px 8px rgba(0,0,0,0.68);

}

.skills--subtitle {
    padding: 35px;
    font-size: 35px;
}

.skills--list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    gap: .2rem;
    flex-wrap: wrap;
}

.skill--card {
    width: 9rem;
    margin: 10px;
    /* border: 2px solid white; */
    border-radius: 20px;
    background: rgba(29, 29, 29, 0.575)
}

.skill--img {
    margin-top: 1rem;
    width: 6rem;
    border-radius: 30px;
    margin-bottom: 5px;
    box-shadow: 0px 8px 10px 3px rgba(0,0,0,0.68);
}

.skill--name {
    /* justify-content: center; */
    font-size: 20px;
    margin-bottom: 20px;
    padding-bottom: 15px;
}