* {
    font-family: 'Quicksand', sans-serif;
    color: white;
}

.contact-container {
    margin: auto;
    width: 70%;
    padding: 20px;
    border: 3px solid white;
    background: linear-gradient(135deg, #403F38 0%, #161a1d 50%, #22265c 100%);
    border-radius: 20px;
    box-shadow: 8px 18px 15px 8px rgba(0,0,0,0.68);
}

.contact-title {
    margin-bottom: px;
    font-weight: 900;
    font-size: 46px;
    text-align: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact-field {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contact-field label {
    font-size: 20px;
}

.contact-field input,
.contact-field textarea {
    padding: 10px;
    border: 3px solid white;
    border-radius: 10px;
    background: rgba(29, 29, 29, 0.575);
    color: white;
    font-size: 18px;
}

.contact-submit {
    padding: 15px;
    border: none;
    border-radius: 10px;
    background-color: #223c5c;
    font-size: 20px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    box-shadow: 0px 8px 10px 3px rgba(0,0,0,0.68);
    transition: background-color 0.3s ease;
}

.contact-submit:hover {
    background-color: #403F38;
}
