/* navbar styles */
.navbar {
  position: fixed;
  width: 80%;
  margin: 10%;
  margin-top: 2%;
  border-radius: 50px;
  display: flex;
  height: auto;
  align-items: center;
  background: rgba(49, 58, 65, 0.678);
  flex-direction: row;
  gap: 2vh;
  padding: 10px 10px;
  box-shadow: 0px 7px 6px rgba(0, 0, 0, 0.25);
  z-index: 3;
  flex-wrap: wrap;
}

.navbar--leftimg {
  margin-right: 6px;
  border-radius: 50%;
  height: 60px;
}

.navbar--name {
  font-size: 2rem;
  margin-right: auto;
  color: white;
  font-family: 'Quicksand', sans-serif;
  letter-spacing: .01cm;
  
}

@media (width <= 550px) {
 .navbar--name{
  display: none;
 } 
 .navbar--menu {
  margin-left: .2rem;
 }
}


.navbar--menu-container {
  position: relative;
}

.navbar--menu {
  font-weight: 500;
  color: white;
  font-size: 25px;
  margin-right: 2rem;
  font-family: 'Quicksand', sans-serif;
  cursor: pointer;
  margin-left: auto;
}

.navbar--dropdown {
  position: absolute;
  top: 200%; 
  right: 10px;
  background-color: rgba(49, 58, 65, 0.9);
  border-radius: 10px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0px 7px 6px rgba(0, 0, 0, 0.25);
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
}

.navbar--dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar--dropdown li {
  padding: 10px;
  color: white;
  font-family: 'Quicksand', sans-serif;
  cursor: pointer;
}

.navbar--dropdown li:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  text-decoration: none;

}

.navbar--menu-container:hover .navbar--dropdown {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.navbar--dropdown--item {
  text-decoration: none;
}