
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');

.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

h1 {
  letter-spacing: .01cm;
}

h3 {
  font-weight: 100;
  margin-top: 8px;
}
h4 {
  font-weight: 100;
  font-size: 25px;
  margin-top: 8px;
}

body {
  margin: 0;
  background-color: rgb(23, 23, 29);
  justify-content: center;
  align-items: center; /* Center vertically */

  /* padding: 10%; */
}
