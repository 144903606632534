.divider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 70px 0; /* Adjust padding as needed */
  }
  
  .divider {
    width: 50%; /* Adjust width as needed */
    height: 4px; /* Thickness of the line */
    background-color: white; /* Line color */
    position: relative;
    overflow: hidden;
  }
  
  .divider::before {
    content: '';
    position: absolute;
    width: 200%;
    height: 100%;
    background: white;
    animation: heartbeat 1.5s infinite cubic-bezier(0.65, 0, 0.35, 1);
    transform: translateX(-100%);
  }
  
  @keyframes heartbeat {
    0% {
      transform: translateX(-100%) scaleX(0.1);
    }
    20% {
      transform: translateX(-50%) scaleX(1.2);
    }
    40% {
      transform: translateX(0%) scaleX(0.8);
    }
    60% {
      transform: translateX(50%) scaleX(1.2);
    }
    80% {
      transform: translateX(100%) scaleX(0.1);
    }
    100% {
      transform: translateX(100%) scaleX(0.1);
    }
  }
  