
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');


* {
  box-sizing: border-box;
}

.landing--header {
  letter-spacing: .01cm;
  font-size: 47px;
  margin-top: 20px;
}

.landing--myname {
  font-size: 24px;
  font-weight: 100;
  margin-top: 8px;
}

.landing--abstract {
  font-weight: 100;
  /* font-size: 25px; */
  margin-top: 8px;
  font-size: clamp(5px, 8vw, 15px); 
}

body {
  margin: 0;
  background-color: rgb(23, 23, 29);
  justify-content: center;
  align-items: center; /* Center vertically */

  /* padding: 10%; */
}

/* landing styles */

.landing {
  display: flex;
  /* height: 50vmh; */
  flex: 1 1 auto;
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 12rem;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center; /* Center items horizontally */
  gap: 3rem;
}

/*left image container*/

.landing--left {
  /* max-width: 50rems; */
  width: 50%;
  min-width: 25rem;
  height: 25rem; /* Maintain aspect ratio */
  object-fit: cover; /* Or 'contain' based on your needs */
  flex-shrink: 0; /* Prevent the image from shrinking */
  border: 2px solid white;
  border-radius: 20px;
  box-shadow: -7px 12px 10px 9px rgba(0,0,0,0.78);
  
}

.landing--right {
  display: flex;
  flex-direction: column;
  float: left;
  /* padding-left: 2rem; */
  width: 25rem; 
  color: white;
  align-self: center;
  text-align: left;
  text-wrap: wrap;
}

.landing--buttongrid {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: .3rem;
}

.landing--button {
  position: relative; /* Required for the pseudo-element positioning */
  height: 40px;
  width: 130px;
  border-radius: 10px;
  border: 1px solid;
  outline: none;
  font-size: 16px;
  background: transparent;
  color: white;
  font-family: 'Oxygen', sans-serif;
  font-weight: 500;
  margin: 3px;
  border-color: white;
  overflow: hidden; /* Ensure pseudo-element doesn’t overflow */
}

/* Add a pseudo-element for the gradient background */
.landing--button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(45deg, #ff6b6b, #f06595, #6b6bff, #f06595);
  background-size: 200%;
  transition: opacity 0.4s;
  z-index: -1;
  opacity: 0;
}

.landing--button:hover::before {
  opacity: 1;
  animation: gradientAnimation 4s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
